<template>
  <b-card>
    <b-overlay :show="show" rounded="sm">
      <b-row>
        <b-col md="2">
          <b-form-input type="text" size="sm" placeholder="Barkod Ara" @keyup.enter="filterBarcode($event,1)"/>
        </b-col>
        <b-col md="2"><h1>{{ _filterBarcode }}</h1></b-col>
        <b-col md="2"><h1 v-if="basketName" :class="'bg-'+basketName.variant">{{ basketName.text }}</h1></b-col>
        <b-col md="1"><h1 v-if="comeNumber" :class="'bg-'+comeNumber.variant">{{ comeNumber.text }}</h1></b-col>
        <b-col md="5"><h1 v-if="eventMessage" :class="'bg-'+eventMessage.variant">{{ eventMessage.text }}</h1></b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions";
import {mapGetters} from "vuex";
import addToCardMixins from "@/mixins/addToCardMixins";
import addToComeMixins from "@/mixins/addToComeMixins";
import soundMixins from "@/mixins/soundMixins";
import {EventBus} from "@/event-bus"

export default {
  name: "OrderFilter",
  components: {BCardActions},
  mixins: [addToCardMixins, addToComeMixins, soundMixins],
  computed: {
    ...mapGetters('order', ['_filterBarcode', "_orders", "_search"]),
    ...mapGetters("orderStatus", ["_orderStatuses"]),
    ...mapGetters('orderBasket', ["_selected"]),
  },
  data: () => ({
    show: false,
    order: {},
    product: {},
    basketName: null,
    comeNumber: null,
    eventMessage: null,
  }),
  mounted() {
    EventBus.$on('setBasketName', (name) => {
      this.basketName = name;
    });
    EventBus.$on('setComeNumber', (number) => {
      this.comeNumber = number
    });
    EventBus.$on('setEventMessage', (message) => {
      this.eventMessage = message
    });
  },
  methods: {
    filterBarcode(event, autAddBasket) {
      this.$store.state.order.autoSelectOrder = null;
      this.basketName = ""
      this.comeNumber = ""
      this.eventMessage = ""
      let isAddBasket = false; //sepete ekle pasif
      let isComeProduct = false;
      let barcode = event.target.value //barkodu alalım
      event.target.value = ""; //search alanını boşaltalım
      this.$store.commit('order/setAutoAddBasket', autAddBasket); //otomatik sepete ekleyi belirtelim
      this.$store.commit('order/setFilterBarcode', barcode); //barkodu filtremize ekleyelim

      if (autAddBasket && this._orders.length > 0 && barcode) { //oto ekleme aktif, sipariş var ve barkodda varsa ok diyelim
        this._orders.forEach(order => {
          if ([1, 2, 3, 13].includes(order.order_status_id) && !order.invoice_basket) {

            if (order.products.length == 1) { //sipariş ürünü tek kalemse buradan devam
              if (
                  order.products[0].quantity == 1
                  && order.products[0].product_barcode == barcode
                  && (![9, 27].includes(order.products[0].supplier_id) || this._search.orderStatus.includes(13))
              ) {
                isAddBasket = true;
              }
            } else {
              let productCount = order.products.length;
              let comeProductCount = 0;
              order.products.forEach(product => {
                if (([9, 27].includes(product.supplier_id) && !this._search.orderStatus.includes(13))
                    || product.come) {
                  comeProductCount++;
                }

                if (product.quantity == 1
                    && product.product_barcode == barcode
                    && (![9, 27].includes(product.supplier_id) || this._search.orderStatus.includes(13))
                    && !product.come && !isComeProduct) {
                  comeProductCount++;
                  isComeProduct = true;
                  this.product = product;
                  this.$store.state.order.autoSelectOrder = this._orders[0];
                  this.comeNumber = 'No Gelecek'
                  this.addToCome()
                }
              })

              if (comeProductCount >= productCount) {
                isAddBasket = true;
              }
            }

          }
        })

        if (isAddBasket) {
          this.order = this._orders[0];
          this.$store.state.order.autoSelectOrder = this._orders[0];
          this.basketName = "Buraya Sepet Adı Gelecek"
          this.addToCard();
        }

      }
    }
  }
}
</script>

<style scoped>

</style>
