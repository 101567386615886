<template>
  <section id="card-text-alignment" class="mb-0">
    <OrderListSearch></OrderListSearch>
    <OrderBasket></OrderBasket>
    <OrderFilter/>
    <b-row>
      <b-col md="12">
        <OrderListOrder
            v-for="order in _orders"
            :key="order.id"
            :order="order"
        />
      </b-col>
    </b-row>
    <OrderPrint></OrderPrint>
    <OrderListTimeLine></OrderListTimeLine>
    <ProductEdit/>
    <ProductImages2></ProductImages2>
  </section>
</template>

<script>
import OrderListSearch from "@/views/order/OrderListSearch";
import OrderListOrder from "@/views/order/list/OrderListOrder";
import BCardActions from "@core/components/b-card-actions/BCardActions";
import OrderFilter from "@/views/order/entry/OrderFilter";
import {mapGetters} from "vuex";
import OrderPrint from "@/views/order/OrderPrint";
import OrderListTimeLine from "@/views/order/list/OrderListTimeLine";
import ProductEdit from "@/views/product/Edit";
import ProductImages2 from "@/components/ProductImages2";
import OrderBasket from "@/views/order/OrderBasket"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  name: "OrderEntryHome",
  components: {
    OrderListSearch,
    OrderListOrder,
    BCardActions,
    OrderFilter,
    OrderPrint,
    OrderListTimeLine,
    ProductEdit,
    ProductImages2,
    OrderBasket
  },
  computed: {
    ...mapGetters('order', ["_orders", "_search"]),
  },
  methods: {
    getAll(pageName) {
      this.$store.commit('order/setFilterBarcode', '');
      this.$store.commit('order/setSearchNull')
      this.$store.commit('order/setOrders', [])
      this.$store.state.order.pagination = null;

      if (pageName == 'preparing') {
        this._search.orderStatus = [1, 2, 3];
        this._search.orderBy = 'order_last_delivery_date';
        this._search.sorting = 'ASC';
        this._search.limit = 10000;

      } else if (pageName == 'collet') {
        this._search.orderStatus = [13];
        this._search.orderBy = 'order_last_delivery_date';
        this._search.sorting = 'ASC';
        this._search.limit = 10000;
      }

      this._search.page = 1;
      this.$store.dispatch("order/getAll")
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: res
            })
          })
    }
  },
  watch: {
    '$route.meta'(to) {
      this.getAll(to.pageName)
    }
  },
  created() {
    this.$store.dispatch('orderStatus/getAll');
    this.$store.dispatch('store/stores');
    this.$store.dispatch('cargoCompany/getAll');
    this.$store.dispatch('productBrand/getAll');
    this.getAll(this.$route.meta.pageName);
  }
}
</script>

<style>
.card {
  margin: 5px;
}

.card-body {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
